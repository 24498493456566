import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './Topbars.css';
import { FaBars, FaTimes } from 'react-icons/fa';

// Remove icon imports

const TopNavbar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const menuItem = [
        {
            path: "/",
            name: "Home"
        },
        {
            path: "/what-we-offer",
            name: "What We Offer"
        },
        {
            path: "/activities",
            name: "Activities"
        },
        {
            path: "/team",
            name: "Our Team"
        },
        {
            path: "/contacts",
            name: "Contact Us"
        },
    ];

    return (
        <div className='container'>
            <div className='topnav'>
                <div className='bars' onClick={toggle}>
                    {isOpen ?
                        <FaTimes />
                        :
                        <FaBars />
                    }
                </div>
                <div className='logo'>
                    <div className='company_text'>Ngnantranga Food Hub Production</div>
                </div>
                <div className={`menu ${isOpen ? 'open' : ''}`}>
                    {
                        menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} className={`link`} activeClassName={'active'} onClick={isOpen ? toggle : () => { }}>
                                <div className={`link_text ${isOpen ? 'open' : ''}`}>{item.name}</div>
                            </NavLink>
                        ))
                    }
                </div>
            </div>
            <main>{children}</main>
        </div>
    );
}

export default connect(null)(TopNavbar);
