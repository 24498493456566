import {combineReducers} from "redux";

//import reducers
import AuthReducer from "./AuthReducer";
import AnalyticsReducer from "./AnalyticsReducer";
import ActivitiesReducer from "./ActivitiesReducer";
import contactReducer from "./ContactReducer";

export default combineReducers({
    auth: AuthReducer,
    analytics: AnalyticsReducer,
    contact: contactReducer,
    activities: ActivitiesReducer,
});