import React from 'react';
import { FaFacebook, FaLinkedin, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section about">
                    <h2>About Us</h2>
                    <p>
                        <span className="about-name">Ngnantranga Food Hub Production</span> is a pioneering enterprise based in Anjouan, Comoros, committed to addressing food insecurity and promoting sustainable development. Specializing in affordable food production, including eggs, quails, chickens, and ducks, we aim to alleviate the burden of high food prices in our community, exacerbated by reliance on imported meat products.
                    </p>
                </div>
                <div className="footer-section links">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/activities">Activities</a></li>
                        <li><a href="/team">Our Team</a></li>
                        <li><a href="/contacts">Contact</a></li>
                    </ul>
                </div>
                <div className="footer-section social">
                    <h2>Follow Us</h2>
                    <div className="follow-us">
                        <div className="social-icons">
                            <a className="whatsapp-link" href="https://wa.me/+2693602159" target='_blank' rel='noreferrer'><FaWhatsapp className="whatsapp-link" /> <span className="whatsapp-text">Kudus Charifaine</span></a>
                        </div>
                        <div className="social-icons">
                            <a className="whatsapp-link" href="https://wa.me/+2694243679" target='_blank' rel='noreferrer'><FaWhatsapp className="whatsapp-link" /> <span className="whatsapp-text">Chamsiddine Afraitane</span></a>
                        </div>
                        <div className="social-icons column-icons">
                            <a className="email-link" href="mailto:chamou3333@gmail.com"><FaEnvelope className="email-link" /></a>
                            <a className="linkedin-link" href="https://www.linkedin.com/company/ngnantranga-food-hub-production/" target='_blank' rel='noreferrer'><FaLinkedin className="linkedin-link" /></a>
                            <a className="facebook-link" href="https://www.facebook.com/people/Ngnantranga-Food-Hub-Production/61560890225834/" target='_blank' rel='noreferrer'><FaFacebook className="facebook-link" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Ngnantranga Food Hub Production. All rights reserved.</p>
                <p>Powered by <a href="https://www.tanzator.com" target="_blank" rel="noopener noreferrer">Tanzator</a></p>
            </div>
        </footer>
    );
}

export default Footer;
