import React, { Suspense, lazy } from 'react';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// import logo from './assets/logo.png';
import back from './assets/backLoader.jpg';

//file imports
import './App.css';
// import Activities from './components/admin/Activities';
// import Contacts from './components/superAdmin/Contacts';
// import Activity from './components/admin/Activity';
// import Team from './components/team/Team';
// import Employee from './components/team/Employee';
import Footer from './components/footer/Footer';
// import NotFound from './components/notFound/NotFound';
import Topbars from './components/topBars/Topbars';
// import Offers from './components/offers/Offers';
// import Home from './components/home/Home';


const Activities = lazy(() => import('./components/admin/Activities'));
const Contacts = lazy(() => import('./components/superAdmin/Contacts'));
const Activity = lazy(() => import('./components/admin/Activity'));
const Team = lazy(() => import('./components/team/Team'));
const Employee = lazy(() => import('./components/team/Employee'));
const NotFound = lazy(() => import('./components/notFound/NotFound'));
const Offers = lazy(() => import('./components/offers/Offers'));
const Home = lazy(() => import('./components/home/Home'));


const firebaseConfig = {
  apiKey: "AIzaSyDoVRVwEd42LrysISbqjM73SQVTdGIiOW8",
  authDomain: "ngnantranga-web.firebaseapp.com",
  projectId: "ngnantranga-web",
  storageBucket: "ngnantranga-web.appspot.com",
  messagingSenderId: "351772340608",
  appId: "1:351772340608:web:75953f8a5e0ebf15585165",
  measurementId: "G-YS4LQ42S5B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const App = () => {



  const renderAccess = () => {

    return (
      <Topbars>
        <div className="mainContainer">
          <Suspense fallback={
            <div className='center-placeholder'>
              <img src={back} alt='Ngnantranga' />
            </div>
          }>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/what-we-offer' element={<Offers />} />
              <Route path='/activities' element={<Activities />} />
              <Route path="/activity/:id" exact element={<Activity />} />
              <Route path='/team' element={<Team />} />
              <Route path="/employee/:id" exact element={<Employee />} />
              <Route path='/contacts' element={<Contacts />} />
              <Route path="*" element={<NotFound />} />

            </Routes>
            <Footer />
          </Suspense>
        </div>
      </Topbars>
    );
    // }
  };


  return (
    <BrowserRouter>
      {/* {user !== null ?  */}
      {renderAccess()}
      {/* : <Spinner />} */}
    </BrowserRouter>
  );


};

export default App;
